import { GroupedGradedStock } from '../../../features/admin/graded-stock/models/graded-stock.model';

export class SetGradedStock {
  public static readonly type = '[Graded Stock] Set graded stock';

  constructor(public stock?: GroupedGradedStock) {}
}

export class ResetGradedStock {
  public static readonly type = '[Graded Stock] Reset graded stock';
}
